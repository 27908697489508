* {
  margin: 0;
  box-sizing: border-box;
}
:root {
  --search_custom_container: 992px;
  --search_custom_container_mobile: 450px;
  --background-change: #fff;
  --primary-color: #046565;
  --primary: linear-gradient(180deg, rgba(4, 101, 101, 0.85), var(--primary-color));
  --form-generic-focus-bxshdw:rgba(4, 101, 101, 0.5);
}
body {
  background-color: var(--background-change);
}
.main_search {
  /*background-color: #fafafa;*/
  /* margin-top: 20px; */
  transition: 0.35s ease;
}
.search_container {
  max-width: var(--search_custom_container);
  width: 100%;
  margin: 0 auto;
  padding: 20px 15px;
  display: flex;
  gap: 10px;
  background-color: var(--background-change);
  position: sticky;
  top: 0;
}
.search_container:last-child {
  /* margin-top: 25%; */
}
.search_group {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
}
.search_group input {
  width: 100%;
  border: 1px solid #dadada;
  padding: 10px;
  min-height: 45px;
  padding-right: 40px;
  border-radius: 6px;
}
.search_group .cm_btn_cancel {
  background-color: transparent;
  cursor: pointer;
  border: none;
  font-size: 24px;
  position: absolute;
  right: 10px;
  height: 30px;
  width: 30px;
}
.search_container .cm_btn_search {
  border: none;
  padding: 10px 30px;
  min-width: 125px;
  font-weight: 600;
  background: var(--primary);
  cursor: pointer;
  border-radius: 6px;
  color: white;
  transition: 0.15s ease;
}
.search_container .cm_btn_search:focus{
  box-shadow: 0 0 0 3px var(--form-generic-focus-bxshdw);
}
.search_group input:focus,
.search_group input:focus-visible {
  outline: none;
  border: 2px solid var(--form-generic-focus-bxshdw);
}
.search_product_list {
  display: flex;
  max-width: var(--search_custom_container);
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  gap: 20px;
}
.search_product_title {
  font-family: sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
}
.search_product_ul {
  list-style: none;
  padding: 0;
}
.search_product_content {
  width: 100%;
  font-size: 14px;
  max-width: 450px;
  padding: 15px;
  background-color: white;
}
.search_product_image {
  flex: 1;
}
.search_product_image img {
  width: 100%;
  border-radius: 6px;
}
.search_product_ul .search_product_item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
}
.search_product_ul .search_product_item:nth-child(odd) {
  background-color: #fafafa;
}
.search_product_item .sr_lst_label {
  font-weight: 500;
  color: #1a1a1a;
}
.search_product_item .sr_lst_span {
  color: black;
}
.search_product_content_inner {
  margin-bottom: 25px;
}
.search_product_sku {
  color: #666;
  margin-top: 8px;
  font-size: 14px;
  display: block;
}
.search_product_price_group {
  margin-bottom: 15px;
}
.search_product_ul .search_product_item.total_item {
  border-top: 1px solid #ededed;
  padding-top: 12px;
  margin-top: 15px;
  font-size: 15px;
  font-weight: bold;
}
.search_product_ul .search_product_item.total_item .sr_lst_label {
  color: black;
  font-weight: bold;
}
.sr_lst_span del {
  margin-right: 10px;
  color: #666;
  font-weight: 400;
}

.error_text{
  margin: auto;
  text-align: center;
  margin-top: 15px;
  color: #3d3d3d;
}

@media screen and (max-width: 991px) {
  .search_product_content {
    max-width: 400px;
  }
}
@media screen and (max-width: 850px) {
  .search_product_list {
    flex-direction: column;
    align-items: center;
    max-width: var(--search_custom_container_mobile);
    gap: 15px;
  }
  .search_container {
    max-width: var(--search_custom_container_mobile);
  }
  .search_product_image {
    display: flex;
    flex-direction: column;
  }
  .search_product_price_group {
    order: 1;
    margin-top: 8px;
  }
  .search_product_title {
    font-size: 16px;
  }
  .search_container {
    gap: 5px;
  }
  .search_container .cm_btn_search {
    padding: 7px 15px;
  }
  .search_product_content {
    max-width: 450px;
  }
}
